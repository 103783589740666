<template>
  <div class="app-container payment-record-component">
    <div class="filter-line">
      <span>
        <el-button @click="getTableList" icon="el-icon-refresh-right">{{
          this.$t("commons.refresh")
        }}</el-button>
        <!-- <el-button type="primary" @click="exportHandler">{{
          this.$t("commons.export")
        }}</el-button> -->
      </span>
      <div
        class="header-filter-button"
        @click="dataset.filterLine.show = !dataset.filterLine.show"
        :class="
          dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'
        "
      >
        <i class="iconfont icon-loudoutu"></i>
        筛选
      </div>
    </div>
    <div>
      <finalTable
        ref="finalTableRef"
        :datas="dataset"
        @tableEventHandler="tableEventHandler"
      >
      </finalTable>
    </div>
    <el-dialog
      :visible.sync="detailDialogShow"
      width="728px"
      :destroy-on-close="true"
      :close-on-click-modal="false"
    >
      <template slot="title">
        <span>{{ dialogTitle }}</span>
      </template>
      <suggestionsForm
        v-if="detailDialogShow"
        :dataset="freightDetailData"
        :pageType="groundRunNum"
        @close="detailDialogClose"
      />
    </el-dialog>
  </div>
</template>

<script>
import finalTable from "@/components/FinalTable";
import suggestionsForm from "./suggestionsForm.vue";
import {
  getSuggestionsList
} from "@/api/ruge/bms/customerService/suggestions";

import { dateFormat } from "@/filters/index";
export default {
  name: "invoiceRecordComponent",
  components: {
    finalTable,
    suggestionsForm,
  },
  data() {
    return {
      dataset: {
        paginationConfig: {
          need: true,
        },
        filterLine: {
          show: true,
        },
        selection: {
          need: false,
        },
        header: [
          { prop: "creationDate", label: "申请时间", width: "" },
          { prop: "ordPersonName", label: "申请人", width: "" },
          { prop: "orderCode", label: "申请Id", width: "" },
          { prop: "ordPersonPhone", label: "联系电话", width: "180" },
          { prop: "enterpriseDescName", label: "申请单位", width: "" },
          { prop: "orderStatus", label: "工单状态", width: "120" },
          { prop: "customerOrderStatus", label: "订单状态", width: "120" },
          { prop: "operation", label: "操作", width: "150" },
        ],
        tableData: [],
        // 搜索行配置
        searchLineConfig: {
          orderCode: {
            type: "input",
            label: "申请Id",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入申请Id",
            prefixIcon: "el-icon-search",
          },
          ordPersonPhone: {
            type: "input",
            label: "联系电话",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入联系电话",
            prefixIcon: "el-icon-search",
          },

          ordPersonName: {
            type: "input",
            label: "申请人",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入申请人名称",
            prefixIcon: "el-icon-search",
          },

          enterpriseDescName: {
            type: "input",
            label: "申请单位",
            value: "",
            actionType: "goSearch",
            placeholder: "请输入申请单位",
            prefixIcon: "el-icon-search",
          },

          orderStatus: {
            type: "selector",
            label: "工单状态",
            value: "",
            placeholder: "请选择工单状态",
            actionType: "goSearch",
            optionList: [
              {
                value: "0",
                label: "已提交",
              },
              {
                value: "1",
                label: "待领取",
              },
              {
                value: "2",
                label: "待执行",
              },
              {
                value: "3",
                label: "执行中",
              },
              {
                value: "4",
                label: "待验收",
              },
              {
                value: "5",
                label: "已完成",
              },
              {
                value: "6",
                label: "报单被驳回",
              },
              {
                value: "7",
                label: "验收被驳回",
              },
              {
                value: "8",
                label: "报单已撤销",
              },
              {
                value: "9",
                label: "已关单",
              },
            ],
          },
          customerOrderStatus: {
            type: "selector",
            label: "订单状态",
            value: "",
            placeholder: "请选择订单状态",
            actionType: "goSearch",
            optionList: [
              {
                value: "1",
                label: "已提交",
              },
              {
                value: "2",
                label: "已取消",
              },
              {
                value: "3",
                label: "处理中",
              },
              {
                value: "4",
                label: "已完成",
              },
              {
                value: "5",
                label: "待修改",
              },
              {
                value: "6",
                label: "待上传",
              },
              {
                value: "7",
                label: "审核通过",
              },
              {
                value: "8",
                label: "报单已撤销",
              },
              {
                value: "9",
                label: "已关单",
              },
            ],
          },

          operation: {
            type: "button",
            filterCount: "",
            actionType: "clickEvent",
            eventName: "showMoreFilters",
            label: "更多筛选",
          },
        },
        // 表格内容配置
        detailConfig: {
          creationDate: {
            type: "dateFormat",
          },
          operation: {
            type: "icon",
            iconList: [
              {
                actionType: "iconClick",
                eventName: "view",
                fontSize: "14px",
                color: "#1A4CEC",
                cursorPointer: "pointer",
                iconName: "el-icon-view",
                tooltips: "查看",
              },
              // {
              //   actionType: "iconClick",
              //   eventName: "upload",
              //   fontSize: "14px",
              //   color: "#1A4CEC",
              //   cursorPointer: "pointer",
              //   iconName: "el-icon-edit",
              //   tooltips: "上传通行证",
              //   hideProp: "flag",
              //   hideValue: false,
              // },
            ],
          },
        },
        // 高级搜索配置
        advanceFilterConfig: {
          creationDate: {
            type: "dateRange",
            pickerType: "date",
            label: "申请时间",
            value: [],
          },
          ordPersonName: {
            inline: true,
            value: "",
          },
          orderCode: {
            inline: true,
            value: "",
          },
          ordPersonPhone: {
            inline: true,
            value: "",
          },
          enterpriseDescName: {
            inline: true,
            value: "",
          },
          orderStatus: {
            inline: true,
            value: "",
          },
          customerOrderStatus: {
            inline: true,
            value: "",
          },
        },
        pageVO: {
          current: 1,
          rowCount: 10,
          total: 0,
        },
      },
      requestParams: {
        current: 1,
        rowCount: 10,
        startTime: null,
        endTime: null,
        orderCode: null,
        ordPersonName: null,
        enterpriseDescName: null,
        orderStatus: null,
        customerOrderStatus: null,
        applyTypeName: "投诉建议",
      },
      loadingFlag: false,
      detailDialogShow: false,
      freightDetailData: null,
      groundRunNum: 1,
      dialogTitle: "",
    };
  },
  watch: {
    loadingFlag: {
      handler(val) {
        this.$refs.finalTableRef.loadingToggle(val);
      },
    },
  },
  created() {
    // if (this.$route.query.goBackFlag) {
    //   const curSearch = sessionStorage.getItem("payment_list_query_params");
    //   if (curSearch) {
    //     const tempObj = JSON.parse(curSearch);
    //     this.requestParams = { ...this.requestParams, ...tempObj };
    //     Object.keys(this.requestParams).forEach((item) => {
    //       if (this.dataset.searchLineConfig[item]) {
    //         this.dataset.searchLineConfig[item].value =
    //           this.requestParams[item];
    //       }
    //     });
    //   }
    // } else {
    //   sessionStorage.removeItem("payment_list_query_params");
    // }
    this.getTableList();
  },
  methods: {
    tableEventHandler(datas) {
      console.log("datas", datas);
      if (datas.type === "goSearch") {
        this.requestParams.current = 1;
        this.dataset.pageVO.current = 1;
        this.dealTime(datas);
        this.workOrder(datas);
        this.requestParams = { ...this.requestParams, ...datas.params };
        this.getTableList();
      } else if (datas.type === "paginationChange") {
        this.requestParams.current = datas.params.current.page;
        this.requestParams.rowCount = datas.params.current.limit;
        this.getTableList();
      } else if (datas.type === "iconClick") {
        switch (datas.eventName) {
          case "view":
            // datas.row
            this.openDetailDialog(datas.row);
            break;
          // case "upload":
          //   this.upLoadPass(datas.row);
        }
      }
    },

    dealTime(datas) {
      if (datas.params.creationDate && datas.params.creationDate.length > 0) {
        this.requestParams.startTime = datas.params.creationDate[0]
          ? dateFormat(datas.params.creationDate[0]) + " 00:00:00"
          : null;
        this.requestParams.endTime = datas.params.creationDate[1]
          ? dateFormat(datas.params.creationDate[1]) + " 23:59:59"
          : null;
        delete datas.params.creationDate;
      }
      return datas;
    },
    workOrder(data) {
      console.log("data", data);
      // if (data.params.orderStatusStr) {
      //   this.requestParams.orderStatus = data.params.orderStatusStr;
      // }
    },
    getTableList() {
      console.log('222222222');
      this.loadingFlag = true;
      getSuggestionsList(this.requestParams)
        .then((res) => {
          console.log("res", res);
          res.rows.forEach((item) => {
            item.orderStatus = item.orderStatusStr;
            item.customerOrderStatus = item.customerOrderStatusStr;
            item.flag = item.customerOrderStatusStr === "待上传" ? true : false;
          });
          this.dataset.tableData = res.rows;
          this.dataset.pageVO.total = res.total;
        })
        .catch(() => {
          this.dataset.tableData = [];
          this.dataset.pageVO.total = 0;
        })
        .finally(() => {
          this.loadingFlag = false;
        });
    },
    // exportHandler() {
    //   evalutionListExport(this.requestParams).then((taskId) => {
    //     this.$message({
    //       type: "success",
    //       message: this.$t("message.operationSuccess"),
    //     });
    //     let exportObj = {
    //       taskId,
    //       taskName: "投诉建议",
    //       taskStatus: 0,
    //       rootPath: "customerWXGSPath",
    //     };
    //     this.$store.dispatch("PushExportNotice", exportObj);
    //   });
    // },
    detailDialogClose(type) {
      //关闭弹窗刷新列表
      this.detailDialogShow = false;
      if (type == true) {
        this.getTableList();
      }
    },
    openDetailDialog(rowData) {
      this.groundRunNum = 1;
      this.dialogTitle = "投诉建议详情";
      this.detailDialogShow = true;
      this.freightDetailData = rowData;
    },
    // upLoadPass(rowData) {
    //   this.groundRunNum = 2;
    //   this.dialogTitle = "投诉建议通行证上传";
    //   this.detailDialogShow = true;
    //   this.freightDetailData = rowData;
    // },
  },
};
</script>

<style lang="less" scoped>
.payment-record-component {
  .filter-line {
    margin-bottom: 18px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    button {
      height: 36px;
    }
    .icon-loudoutu {
      font-size: 12px;
    }
    .header-filter-button {
      cursor: pointer;
      width: 84px;
      height: 36px;
      line-height: 36px;
      text-align: center;
      border-radius: 3px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
    }
    .filter-button-open {
      color: #409eff;
      background: #ecf5ff;
      border: 1px solid #c6e2ff;
    }
    .filter-button-close {
      color: #2a4158;
      background: #ffffff;
      border: 1px solid #e7e8eb;
    }
  }
  .link-span {
    cursor: pointer;
    color: #409eff;
  }
}
</style>
import request from '@/utils/request';
import { envInfo } from '@/constants/envInfo';

/**
 * 投诉建议 - 分页查询
 * @param params
 */
export function getOldSuggestionsList(params) {
  return request({
    url:
      envInfo.bgApp.customerServicePath +
      '/complaintAdvice/queryComplaintAdviceList',
    method: 'get',
    params,
  });
}

/**
 * 投诉建议 - 新增
 * @param params
 */
export function addComplaintAdvice(params) {
  return request({
    url:
      envInfo.bgApp.customerServicePath + '/complaintAdvice/addComplaintAdvice',
    method: 'post',
    data: params,
  });
}

/**
 * 投诉建议 - 获取详情
 * @param params
 */
export function getComplaintAdviceDetail(params) {
  return request({
    url: envInfo.bgApp.customerServicePath + '/complaintAdvice/getDetails',
    method: 'get',
    params,
  });
}

/**
 * 投诉建议 - 修改
 * @param params
 */
export function updateComplaintAdviceDetail(params) {
  return request({
    url:
      envInfo.bgApp.customerServicePath +
      '/complaintAdvice/updateComplaintAdvice',
    method: 'post',
    data: params,
  });
}

/**
 * 投诉建议 - 处理
 * @param params
 */
export function handleComplaintAdvice(params) {
  return request({
    url:
      envInfo.bgApp.customerServicePath +
      '/complaintAdvice/handleComplaintAdvice',
    method: 'post',
    data: params,
  });
}

/**
 * 投诉建议 - 删除
 * @param params
 */
export function deleteComplaintAdvice(params) {
  return request({
    url:
      envInfo.bgApp.customerServicePath +
      '/complaintAdvice/deleteComplaintAdvice',
    method: 'post',
    data: params,
  });
}

/**
 * 投诉建议 - new查询
 * @param params
 */

export function getSuggestionsList(params) {
  return request({
    url:
      envInfo.bgApp.customerWXServicePath +
      '/order/queryCustomerList',
    method: 'get',
    params,
  });
}